









// --- Vue & Template imports ---
import { Component, Prop, Vue } from 'vue-property-decorator';

// --- Models ---

// --- Services ---

// --- Third Party ---

@Component
export default class HomePage extends Vue {
}
